import React, { Component } from "react";
import cx from 'classnames';
import BrokerFinderSearchStyle from '../../assets/styles/brokerFinderSearch.module.css';

import Autocomplete from "../../components/Autocomplete";

class BrokerFinderSearch extends Component {
    constructor() {
    super();
    }

    render(){
        if(!this.props.searchKenticoLabel){
       return (<div></div>)
        }
       // alert(this.props.searchKenticoLabel.edges[0].node);
         const searchKenticoLabel= this.props.searchKenticoLabel.edges[0].node.elements
         let customClass = (this.props.searchPopup) ? 'serchbrokrPopupSectionMain' : '';
         let simplified = (searchKenticoLabel.simplified) ? BrokerFinderSearchStyle.simplified : '';
        return(
                    <section className={cx(customClass, simplified, BrokerFinderSearchStyle.dummyClass)}>
                        <div>
                            <section className={cx(BrokerFinderSearchStyle.serchtopBrokrSection, 'text-center serchbrokrPopupSection')}>
                                    <div className={'container'}>
                                        <div className={'row'}>
                                            <div className={cx(BrokerFinderSearchStyle.serchtopBrokrContnt, 'col-lg-8 col-md-9 col-12 mr-auto ml-auto serchbrokrPopupContnt')}>
                                                <h2 className={cx('popupsearchTitle', BrokerFinderSearchStyle.sectionTitle)}>{searchKenticoLabel.search_textheading.value}</h2>
                                                <Autocomplete searchkenticoPlaceholder={searchKenticoLabel} />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                          </div>
                        </section>
                        )
                    }
                }

export default BrokerFinderSearch;