import React, { Component, Fragment } from "react";
import cx from 'classnames';
// import Styles from '../assets/styles/brokerFinder.module.css';
import Styles from '../assets/styles/autoSearchBroker.module.css';
import Axios from "axios";
import Cookies from 'universal-cookie';
import { navigate } from "gatsby"
import { log, host } from '../helpers/commons'


class Autocomplete extends Component {
  

  constructor(props) {
    super(props);  

    this.state = {
     suggestions: [],
     userInput:"",
     suggestionClass:'closeSuggestionClass',
     message:'Please select postcode from list',
     requestpostcode:'',
     flag:false,
     errorMsgClass:'errorMsgHide',
     postcodeId:'',
     placeholder:this.props.searchkenticoPlaceholder.search_placeholder.value
   };
   this.onChange=this.onChange.bind(this);
   this.onClick=this.onClick.bind(this);
   this.onClickSearch=this.onClickSearch.bind(this);
   this.onKeyDown=this.onKeyDown.bind(this);
   
  }

   onChange(event){
    let userInput= event.currentTarget.value;
    let postcode=[];
    let _this=this;
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    this.state.flag=false;
    if(userInput.length>=3 ){
      if(parseInt(userInput) != 0 || parseInt(userInput)=='NaN'){
        const url =`${host}/gatsby-api/suburblookup?suburblookup=${userInput}`
          if(this.state.requestpostcode){
            source.cancel();
          }
          this.state.requestpostcode= Axios.get(url, {
                cancelToken: source.token
              }).catch(function (error) {
                if (Axios.isCancel(error)) {
                  console.log('Request canceled');
                } else {
                 console.error(error);
               }
             }).then(function (response) {
                if(response){
                 postcode = response.data.postcodes; 
                 if(postcode.length){
                    _this.setState({
                      suggestions: postcode,
                      suggestionClass:'openSuggestionClass',
                      message:'Please select postcode from list',
                      requestpostcode:null                   
                    });  
                  }else{
                    _this.setState({
                      suggestions: postcode,
                      message:'Please enter a valid postcode',
                      requestpostcode:null
                    }); 
                  }     
                }else{
                  console.error('no response');
                }                               
          })  
       }
   }
     if(userInput.length > 0){
        _this.setState({
          userInput: event.currentTarget.value,
          errorMsgClass:'errorMsgHide'
        }); 
      }else{
        _this.setState({
          userInput: event.currentTarget.value,
          suggestionClass:'closeSuggestionClass',
          errorMsgClass:'errorMsgHide'
        });
      }

   }

   onClick(event,id, postcode){
    let _this=this;
    _this.setState({
      userInput: event.currentTarget.innerText,
      suggestionClass:'closeSuggestionClass',
      postcodeId:id,
      longitude: postcode.longitude,
      latitude: postcode.latitude,
      flag:true
    });
   }

   onClickSearch(event){
    var userInput=this.state.userInput;
    var flag=this.state.flag;
    let _this=this;
    let url='';
    if(userInput && flag){
      var postcodes = userInput.split(',');
      const cookies = new Cookies();
      cookies.set('postcode_id', this.state.postcodeId, { path: '/' });
      cookies.set('postcode', postcodes[0], { path: '/' });
      cookies.set('locality', postcodes[1], { path: '/' });
      cookies.set('statecode',postcodes[2], { path: '/' });
      cookies.set('longitude',this.state.longitude, { path: '/' });
      cookies.set('latitude', this.state.latitude, { path: '/' });
      
      url=`/mortgage-broker-finder`
      return navigate(url)
    }else{
       _this.setState({
            suggestions: [],
            message:'Please enter a valid postcode.',
            suggestionClass:'closeSuggestionClass',
            errorMsgClass:'errorMsgShow'
          }); 
    }
   }
  
  onKeyDown(){
     let _this=this;
      _this.setState({
        suggestions: [],
        message:'Please enter a valid postcode.',
        suggestionClass:'closeSuggestionClass',
      });
  }


  render() {
     const suggestionClass = this.state.suggestionClass;
     const suggestionMessage=this.state.message;
     const errorMessage=this.state.errorMsgClass;
     const searchKenticoPlaceHolder= this.props.searchkenticoPlaceholder
     let simplified = (this.props.searchkenticoPlaceholder.simplified) ? Styles.simplified : '';
   
  return (
      <div className={cx(Styles.serchsuburb, simplified)}>
            <div className={cx(Styles.inputGroup, 'input-group')}>
                <input type="text" className={cx(Styles.formControl, 'form-control')} onChange={this.onChange} onKeyDown={this.onKeyDown} value={this.state.userInput} placeholder={this.state.placeholder} onFocus={() => this.setState({placeholder: ''})} name="" />
                <div className={cx(Styles.inputGroupAppend, 'input-group-append')}>
                    <button className={cx(Styles.serchbtn, 'btn')} type="submit" onClick={this.onClickSearch}>{searchKenticoPlaceHolder.search_buttontext.value}</button>
                </div>
               
            </div>
           
            <div className={cx(Styles.suburbContainer, Styles.findersuburbcontainer, Styles[suggestionClass])}>
                <div className={Styles.autoSuggestCodes}>
                    <div className={Styles.arrowup}></div>
                    <p className={Styles.selectPostcodeHeding} style={{display:'none'}}>
                    {suggestionMessage}
                    </p>
                    <ul>
                      {
                      this.state.suggestions.map((postcode) =>                         
                        <li key={postcode.id} onClick={(event)=>{this.onClick(event, postcode.id, postcode)}}>
                            <a href="javascript://"  >{`${postcode.postcode}, ${postcode.locality}, ${postcode.statecode}`} </a>
                        </li>
                      )
                    }
                    </ul>
                </div>
            </div>
            <p className={cx(Styles.errorMsg, Styles[errorMessage])}>{suggestionMessage}</p>
        </div>      

    );
  }
}

export default Autocomplete;
